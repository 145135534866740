import React, { Component } from 'react'
import Layout from '../components/Layout'
import OurCompanyNavigationRow from '../components/OurCompanyNavigationRow'

export default class LeadsPlusTermsOfService extends Component {
    render() {
        return (
            <Layout
                title="Terms of Service | HomeSpotter"
                description="Read about our stated terms of service at homespotter.com"
            >
                <OurCompanyNavigationRow location={this.props.location} />
                <div className="ourcompany terms terms-of-service">
                    <h1>Leads+ TERMS OF SERVICE</h1>
                    <p>
                        Leads+ is a product of Lone Wolf Real Estate
                        Technologies, Inc. and its affiliates HomeSpotter and
                        LionDesk (collectively “Lone Wolf”).
                    </p>
                    <p>
                        You acknowledge and confirm (a) that you are signing up
                        for a minimum commitment term (“Initial Term”) as
                        outlined on the order screen with automatically renewing
                        terms of one month following that (“Renewal Term”), (b)
                        that you are submitting your contact information to Lone
                        Wolf, (c) that you agree to{' '}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.lwolf.com/about/terms-of-service"
                        >
                            Lone Wolf's Terms of Service
                        </a>
                        , (d) that you agree to{' '}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://liondesk.com/eula.html"
                        >
                            LionDesk&apos;s User Agreement
                        </a>
                        , (e) that you have read and understand the{' '}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.liondesk.com/privacy.html"
                        >
                            LionDesk privacy policy
                        </a>
                        , and (f) that you are expressly consenting to marketing
                        calls or texts from Lone Wolf and its authorized
                        partners and their respective affiliates, contractors
                        and agents through use of an automatic telephone dialing
                        system and/or artificial or prerecorded voice to the
                        phone number you provided that may be recorded or
                        monitored. Consent is not a condition of purchase and
                        you may call LionDesk Support to speak to someone
                        directly.
                    </p>
                </div>
            </Layout>
        )
    }
}
